.body {
  padding-left: 10rem;
  padding-right: 10rem;
  font-family: "cairo";
}

.checkOut-content {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 4rem;
  direction: rtl;
  overflow: hidden;
  margin-bottom: 15rem;
  padding-top: 7rem;
}

.receipt-details {
  font-family: "cairo";
  color: black;
}

.receipt-details_text {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.checkOutDetails-container {
  display: grid;
  grid-template-columns: 50% 50%;
  direction: rtl;
}

.checkOut-input {
  padding: 0.8rem;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-family: "cairo";
}

.receipt {
  padding-top: 1rem;
  padding-right: 3rem;
  padding-left: 3rem;
  font-family: "Cairo";
  background-color: #ffffff;
  margin-right: 2rem;
  border-radius: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 2px 4px,
    rgba(0, 0, 0, 0.1) 0px 7px 13px -3px,
    rgba(255, 255, 255, 0.8) 0px -3px 0px inset;
}

.receipt_text {
  text-align: center;
  color: black;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.receiptDetails_content {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.receiptDetails_content_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 1rem;
}

.receiptDetails_Text {
  font-weight: bold;
  font-size: 0.9rem;
}

.receiptDetails_content_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 1rem;
}

.marginTop {
  margin-top: 1rem;
}

.primaryButton {
  width: 100%;
  cursor: pointer;
  /* height: 2.7rem; */
  background-color: #48b3bd;
  color: white;
  border-radius: 5px;
  font-size: 1rem;
  font-family: "cairo";
  transition: all 0.2s;
  font-weight: bold;
  padding: 0.5rem;
}

.primaryButton:hover {
  transform: translateY(-5px);
}

@media only screen and (max-width: 767px) {
  .body {
    padding-left: 0;
    padding-right: 0;
  }
  .checkOut-content {
    display: grid;
    grid-template-columns: 100%;
    padding: 1rem;
    margin-top: 7rem;
  }

  .receipt {
    margin-top: 1rem;
    padding-bottom: 1rem;
    margin-right: 0;
  }
}
