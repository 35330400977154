
.auction-button {
  background-color: #efeded;
  color: #42261b;
  margin-left: 10px;
}

.auctions-options{
    background-color: #efeded;
    border-radius: 10px;
}

.auction-button:hover {
  background-color: #48B3BD;
  color: white;
}



@media (min-width: 768px) {
  .auctions-page {
    margin-left: 5%;
    margin-right: 5%;
  }
}
