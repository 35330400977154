/* src/AboutUs.css */
.about-container {
  max-width: 900px;
  
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: right;
  direction: rtl;
  margin-top: 7rem;
  margin-bottom: 2rem;
}

.about-us-header {
  color: #333;
  text-decoration: underline;
  margin-bottom: 20px;
}

.about-us-paragraph {
  color: #555;
  font-size: 18px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .about-container{
    margin-left: 5%;
    margin-right: 5%;
  }
}
